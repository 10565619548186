import { createApi } from '@reduxjs/toolkit/query/react';

import { Paychecks } from 'types';
import { URL } from 'api/constants';
import { addParamsToUrl } from 'utils/query';
import { eventApi, tagTypes as eventTagTypes } from 'store/api/event';

import { apiQuery } from '../../query';

import { transformPayoutData } from './helpers';
import {
  Payout,
  PaymentHistory,
  MinusPayoutTypes,
  PayoutListRequest,
  EditPayoutRequest,
  ChangeStatusRequest,
  CreatePayoutRequest,
  TransformPayoutData,
  CreatePayrollRequest,
  PaymentHistoryRequest,
} from './types';

export const tagTypes = ['Payout'];

export const payoutApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'payoutApi',
  endpoints: (build) => ({
    details: build.query<Payout, string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: URL.GET_PAYOUT_DETAILS.replace(':id', id),
      }),
    }),

    pending: build.query<Payout[], string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: URL.GET_PENDING_PAYOUTS.replace(':id', id),
      }),
    }),

    createPayroll: build.mutation<Payout, CreatePayrollRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.CREATE_PAYROLL,
      }),
    }),

    employeeSalary: build.query<Paychecks[], number>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: URL.GET_EMPLOYEE_SALARY.replace(':id', String(id)),
      }),
    }),

    edit: build.mutation<Payout, EditPayoutRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'put',
        url: URL.UPDATE_PAYOUT.replace(':id', String(id)),
      }),
    }),

    changeStatus: build.mutation<Payout, ChangeStatusRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: URL.CHANGE_PAYOUT_STATUS.replace(':id', String(id)),
      }),
    }),

    paymentHistory: build.query<PaymentHistory[], PaymentHistoryRequest>({
      providesTags: tagTypes,
      query: ({ id, search }) => ({
        method: 'get',
        url: addParamsToUrl(URL.GET_PAYOUT_HISTORY.replace(':id', String(id)), {
          search,
        }),
      }),
    }),

    list: build.query<TransformPayoutData, PayoutListRequest | void>({
      providesTags: tagTypes,
      transformResponse: (data: Payout[]) => transformPayoutData(data),
      query: (query) => ({
        method: 'get',
        url: query ? addParamsToUrl(URL.GET_PAYOUTS, query) : URL.GET_PAYOUTS,
      }),
    }),

    delete: build.mutation<void, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: URL.UPDATE_PAYOUT.replace(':id', id),
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(eventApi.util.invalidateTags(eventTagTypes));
        } catch (error) {
          console.error(error);
        }
      },
    }),

    create: build.mutation<Payout, CreatePayoutRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.GET_PAYOUTS,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(eventApi.util.invalidateTags(eventTagTypes));
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useListQuery,
  useDetailsQuery,
  useEditMutation,
  usePendingQuery,
  useCreateMutation,
  useDeleteMutation,
  useEmployeeSalaryQuery,
  usePaymentHistoryQuery,
  useChangeStatusMutation,
  useCreatePayrollMutation,
} = payoutApi;

export type { Payout, PaymentHistory, TransformPayoutData };
export { MinusPayoutTypes };
