import { type JSX, cloneElement } from 'react';
import Dropzone, { ErrorCode, FileRejection } from 'react-dropzone';

import { useAlert } from 'hooks';
import { UploadFile } from 'types';

import * as Styles from './styles';
import { DropZoneProps } from './types';

const DropZone = ({
  accept,
  setValue,
  children,
  disabled,
  maxFiles,
  className,
  errorMessage,
  noClick = false,
  multiple = false,
  ...props
}: DropZoneProps): JSX.Element => {
  const { setIsError, setIsLoading, allowedFileSize = 1 } = props;
  const allowedSize = allowedFileSize * 1024 * 1024;

  const { showErrorAlert } = useAlert();

  const handleDrop = async (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    if (acceptedFiles.length > 0) {
      if (setIsLoading) {
        setIsLoading(true);
      }

      const values = await Promise.all(
        acceptedFiles.map((item) => {
          const reader = new FileReader();

          return new Promise((resolve) => {
            reader.onload = () => {
              resolve({
                file: item,
                src: reader.result,
              });
            };
            reader.readAsDataURL(item);
          });
        })
      );
      if (setIsError) {
        setIsError(false);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }

      if (multiple) {
        setValue(values as UploadFile[]);
      } else {
        setValue(values[0] as UploadFile);
      }
    }

    if (rejectedFiles.length > 0) {
      if (setIsError) {
        setIsError(true);
      }

      if (errorMessage) {
        const errorCode = rejectedFiles[0].errors[0].code;

        if (errorCode === ErrorCode.FileTooLarge) {
          showErrorAlert(`Only files less then ${allowedFileSize}MB allowed.`);
        } else if (errorCode === ErrorCode.FileInvalidType && accept) {
          showErrorAlert(
            `File type must be ${Object.keys(accept).join(', ')}.`
          );
        }
      }
    }
  };

  return (
    <Dropzone
      accept={accept}
      noClick={noClick}
      multiple={multiple}
      maxFiles={maxFiles}
      disabled={disabled}
      onDrop={handleDrop}
      maxSize={allowedSize}
      data-testid="drop-zone"
    >
      {({ getRootProps, getInputProps }) => (
        <Styles.Wrapper {...getRootProps()} className={className}>
          <Styles.Input {...getInputProps()} />

          {cloneElement(children, { ...props })}
        </Styles.Wrapper>
      )}
    </Dropzone>
  );
};

export { DropZone };
export type { DropZoneProps };
