import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { SearchRequest } from 'types';
import { formatDate } from 'utils/date';
import { addParamsToUrl, addSearchParams } from 'utils/query';
import {
  Storage,
  MonthlyPrice,
  StorageFormValues,
  StorageDetailsRequest,
  StorageCreateFormValues,
} from 'store/api/storage/types';

import { apiQuery } from '../../query';

export const tagTypes = ['storages'];

const getFormattedStorageData = (data: Storage) => ({
  ...data,
  monthlyPrices: data.monthlyPrices
    .filter((item) => Object.values(item).filter(Boolean).length > 0)
    .map((item) => ({ ...item, date: formatDate(item.date) })),
});

export const storagesApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'storagesApi',
  endpoints: (build) => ({
    remove: build.mutation<void, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: URL.STORAGE.replace(':id', id),
      }),
    }),

    monthlyPaid: build.mutation<MonthlyPrice, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'post',
        url: URL.STORAGE_MONTHLY_PAID.replace(':id', id),
      }),
    }),

    list: build.query<Storage[], (SearchRequest & Partial<Storage>) | void>({
      providesTags: tagTypes,
      query: (params) => ({
        method: 'get',
        url: addSearchParams(URL.STORAGES, params),
      }),
    }),

    details: build.query<Storage, StorageDetailsRequest>({
      providesTags: tagTypes,
      query: ({ id, ...queries }) => ({
        method: 'get',
        url: addParamsToUrl(URL.STORAGE.replace(':id', id), queries),
      }),
    }),

    create: build.mutation<void, StorageCreateFormValues>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        method: 'post',
        url: URL.STORAGES,
        data: getFormattedStorageData(data as Storage),
      }),
    }),

    edit: build.mutation<Storage, StorageFormValues>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        method: 'patch',
        url: URL.STORAGE.replace(':id', String(id)),
        data: getFormattedStorageData(data as Storage),
      }),
    }),
  }),
});

export const {
  useListQuery,
  useEditMutation,
  useDetailsQuery,
  useRemoveMutation,
  useCreateMutation,
  useMonthlyPaidMutation,
} = storagesApi;

export type {
  Storage,
  MonthlyPrice,
  StorageFormValues,
  StorageCreateFormValues,
};
