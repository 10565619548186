import { Icon } from 'components/icon';

import { CardType } from './enums';

export const CardTypeIcon = {
  [CardType.Visa]: <Icon.Visa />,
  [CardType.Discover]: <Icon.Discover />,
  [CardType.UnionPay]: <Icon.UnionPay />,
  [CardType.Mastercard]: <Icon.Mastercard />,
  [CardType.AmericanExpress]: <Icon.AmericanExpress />,
};
