import styled, { css } from 'styled-components';

import { DeleteButton } from 'components/specified-buttons';

export const StyledDeleteButton = styled(DeleteButton)`
  ${({ theme }) =>
    !theme.responsive.isMobile &&
    css`
      margin-top: 32px;
    `}
`;
