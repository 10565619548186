import { mapQuery } from 'utils/query';
import { useAppSelector } from 'store';
import { formatDate } from 'utils/date';
import { formatPrice } from 'utils/helpers';
import { selectAuthUser } from 'store/slices/auth';
import { Payout, useListQuery } from 'store/api/payout';
import { useFilter, useShowMore, useSearchQuery } from 'hooks';
import { Role, Roles, PayoutType, PayoutStatus, BackOfficeRoles } from 'types';
import {
  ListCard,
  ListHeader,
  PayoutStatusBadge,
  ApprovalPayoutDialog,
} from 'components';

import * as CommonStyles from '../../styles';

import { ReimbursementProps } from './types';
import { CreateReimbursement } from './create';

const titles = ['Date', 'Employee', 'Description', 'Amount', 'Status'];
const getTemplateAndTitles = (isCompanyOwner: boolean) => {
  let template = 'repeat(5, 1fr)';
  const currentTitles = [...titles];

  if (isCompanyOwner) {
    template += ' 96px';
    currentTitles.push('Approve');
  }

  return { template, currentTitles };
};

export const Reimbursement = ({ event }: ReimbursementProps) => {
  const authUser = useAppSelector(selectAuthUser);
  const { debounceQuery, ...search } = useSearchQuery();
  const { data, ...props } = useListQuery({
    eventId: String(event.id),
    types: JSON.stringify([
      PayoutType.Reimbursements,
      PayoutType.PrepaidReimbursements,
    ]),
  });

  const filteredValues = useFilter<Payout>({
    query: debounceQuery,
    data: data?.reimbursements || [],
    paths: ['date', 'notes', 'amount', 'event.name', 'employee.user.name'],
  });

  const canCreateReimbursement = (
    [BackOfficeRoles.MANAGER, Roles.COMPANY_OWNER] as Role[]
  ).includes(authUser?.role?.name!);

  const { visibleData, ...showMoreProps } = useShowMore(filteredValues);

  const isCompanyOwner = authUser?.role?.name === Roles.COMPANY_OWNER;
  const { template, currentTitles } = getTemplateAndTitles(isCompanyOwner);

  return (
    <CommonStyles.StyledList
      {...mapQuery(props)}
      {...search}
      {...showMoreProps}
      count={visibleData?.length}
      header={<ListHeader template={template} titles={currentTitles} />}
      searchBarRightSlot={
        canCreateReimbursement ? (
          <CreateReimbursement event={event} />
        ) : undefined
      }
    >
      {visibleData?.map((value) => {
        const values = [
          formatDate(value?.date),
          value?.employee?.user?.name,
          value?.notes,
          formatPrice(value?.amount),
          <PayoutStatusBadge type={value?.type} status={value?.status} />,
        ];

        if (isCompanyOwner && value?.status === PayoutStatus.Pending) {
          values.push(<ApprovalPayoutDialog data={value} />);
        }

        return (
          <ListCard
            padding="16px"
            key={value.id}
            values={values}
            template={template}
            headers={currentTitles}
          />
        );
      })}
    </CommonStyles.StyledList>
  );
};
