import { useParams } from 'react-router-dom';

import { isMobile } from 'hooks';
import {
  Dialog,
  NewEntityButtonWithRef,
  CreateAccountingExpenses,
} from 'components';

export const CreateExpense = () => {
  const mobile = isMobile();
  const { id } = useParams();
  return (
    <Dialog
      removeAutofocus
      title="Create Expense"
      width={mobile ? '90vw' : '720px'}
      trigger={<NewEntityButtonWithRef text="New Expense" />}
    >
      {(close) => (
        <CreateAccountingExpenses
          isDialog
          onClose={close}
          onSuccess={close}
          eventId={Number(id)}
        />
      )}
    </Dialog>
  );
};
