import { Formik } from 'formik';
import { useMemo, useState } from 'react';

import { isMobile } from 'hooks';
import { formatDate } from 'utils/date';
import { capitalize } from 'utils/string';
import { MinusPayoutTypes } from 'store/api/payout';
import { formatPrice, joinStrings } from 'utils/helpers';
import {
  PayoutType,
  BadgeColor,
  BadgePadding,
  ButtonColors,
  ButtonStyleTypes,
} from 'types';
import {
  Flex,
  Link,
  Icon,
  Dialog,
  Button,
  InfoBlock,
  ButtonTypes,
  CommonStyles,
  ErrorMessage,
  PayoutStatusBadge,
} from 'components';

import * as Styles from './styles';
import { payoutTitles } from './config';
import { AddRowDialog } from './add-row-dialog';
import {
  CardProps,
  PriceProps,
  PayoutInfoProps,
  PayoutDialogProps,
  TotalInfoCardProps,
} from './types';

const Card = ({ title, value }: CardProps) => (
  <Flex gap={16} fullWidth alignItems="center" justifyContent="space-between">
    <p>{title}</p>
    {typeof value === 'string' ? <p>{value}</p> : value}
  </Flex>
);

const Price = ({ payout, totalPrice, showPayoutPrice }: PriceProps) => (
  <Styles.StyledBadge
    padding={BadgePadding.Big}
    styleType={BadgeColor.Blue}
    text={
      <>
        <p className="semibold">Payout</p>
        <p className="semibold">
          {showPayoutPrice ? formatPrice(payout?.amount) : totalPrice}
        </p>
      </>
    }
  />
);

const PayoutInfo = ({
  payout,
  totalPrice,
  onBackClick,
  showPayoutPrice,
}: PayoutInfoProps) => (
  <Flex gap={16} fullWidth flexDirection="column">
    {!!onBackClick && (
      <div>
        <Button
          padding="0"
          onClick={onBackClick}
          icon={<Icon.ChevronLeft />}
          text={`Payroll #${payout?.id}`}
          colorType={ButtonColors.Transparent}
        />
      </div>
    )}
    <Styles.Title>
      Event: <span className="semibold">{payout?.event?.name}</span>
    </Styles.Title>

    <Flex gap={8} flexDirection="column">
      <InfoBlock>
        <Flex gap={16} flexDirection="column">
          <Card title="Employee name" value={payout?.employee?.user?.name} />
          <Card title="Date" value={formatDate(payout?.date)} />
          {payout?.status && (
            <Card
              title="Status"
              value={
                <PayoutStatusBadge type={payout.type} status={payout.status} />
              }
            />
          )}
          <Card title="Type" value={payout?.type} />
          {payout?.receiptUrl && (
            <Card
              title="Receipt"
              value={
                <Link withoutButton text="Download" url={payout.receiptUrl} />
              }
            />
          )}
          <Card title="Notes" value={payout?.notes} />
        </Flex>
      </InfoBlock>
      <Price
        payout={payout}
        totalPrice={totalPrice}
        showPayoutPrice={showPayoutPrice}
      />
    </Flex>
  </Flex>
);

const TotalInfoCard = ({ type, title, selectedValues }: TotalInfoCardProps) => {
  const filteredValues = selectedValues.filter((value) => value.type === type);

  return filteredValues.length ? (
    <Card
      title={title}
      value={formatPrice(
        filteredValues.reduce((acc, value) => acc + +value.amount, 0)
      )}
    />
  ) : null;
};

export const PayoutDialog = ({
  onClose,
  onSubmit,
  hideButtons,
  selectedValues,
  setSelectedValues,
}: PayoutDialogProps) => {
  const mobile = isMobile();
  const isSeveral = selectedValues?.length > 1;
  const [payout, setPayout] = useState(
    isSeveral ? undefined : selectedValues[0]
  );

  const payPrice = selectedValues.reduce(
    (acc, val) =>
      MinusPayoutTypes.includes(val.type)
        ? acc - Math.abs(val.amount)
        : acc + Math.abs(val.amount),
    0
  );
  const totalPrice = formatPrice(payPrice);

  const isPayrollsNegative = payPrice <= 0;

  const { additionValues, deductionValues } = useMemo(
    () => ({
      deductionValues: selectedValues.filter((value) =>
        MinusPayoutTypes.includes(value.type)
      ),
      additionValues: selectedValues.filter(
        (value) => !MinusPayoutTypes.includes(value.type)
      ),
    }),
    [selectedValues]
  );

  const getBlock = (isDeduction?: boolean) => (
    <InfoBlock>
      <Flex gap={16} flexDirection="column">
        <Styles.Title>{isDeduction ? 'Deductions' : 'Additions'}</Styles.Title>
        {(isDeduction
          ? MinusPayoutTypes
          : Object.values(PayoutType).filter(
              (value) => !MinusPayoutTypes.includes(value)
            )
        ).map((value) => (
          <TotalInfoCard
            key={value}
            type={value}
            title={capitalize(payoutTitles[value] || value)}
            selectedValues={isDeduction ? deductionValues : additionValues}
          />
        ))}
      </Flex>

      {!hideButtons && (
        <AddRowDialog
          isDeduction={isDeduction}
          setSelectedValues={setSelectedValues}
        />
      )}
    </InfoBlock>
  );

  const allPayoutInfo = (
    <Flex gap={16} flexDirection="column">
      <Styles.Title>
        Events:{' '}
        <span className="semibold">
          {joinStrings(
            Array.from(
              new Set(selectedValues.map((value) => value?.event?.name))
            ),
            ', '
          )}
        </span>
      </Styles.Title>

      {additionValues.length > 0 && getBlock()}
      {deductionValues.length > 0 && getBlock(true)}
    </Flex>
  );

  const payoutInfo =
    isSeveral && !payout ? (
      <Flex gap={8} fullWidth flexDirection="column">
        {allPayoutInfo}

        <Price payout={payout} totalPrice={totalPrice} />
      </Flex>
    ) : (
      <PayoutInfo
        payout={payout}
        totalPrice={totalPrice}
        showPayoutPrice={isSeveral}
        onBackClick={isSeveral ? () => setPayout(undefined) : undefined}
      />
    );

  return (
    <Dialog
      open
      onClose={onClose}
      width={mobile ? '90vw' : '520px'}
      closeOnDocumentClick={Boolean(hideButtons)}
      title={isSeveral ? 'Pay several payrolls' : `Payroll ${payout?.id}`}
    >
      {(close) =>
        !!onSubmit ? (
          // TODO: add step with payment method
          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <CommonStyles.Form gap={24}>
                {payoutInfo}

                {isPayrollsNegative && (
                  <ErrorMessage>
                    The total must be greater than zero
                  </ErrorMessage>
                )}
                {!hideButtons && (
                  <Flex gap={16} fullWidth>
                    <Button
                      fullWidth
                      text="Cancel"
                      onClick={close}
                      styleType={ButtonStyleTypes.Outline}
                    />
                    <Button
                      fullWidth
                      type={ButtonTypes.Submit}
                      text={`Pay (${totalPrice})`}
                      disabled={isSubmitting || isPayrollsNegative}
                    />
                  </Flex>
                )}
              </CommonStyles.Form>
            )}
          </Formik>
        ) : (
          payoutInfo
        )
      }
    </Dialog>
  );
};
