import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { addParamsToUrl } from 'utils/query';

import { eventApi, tagTypes as eventTagTypes } from '../event';

import { Refund, CreateRefund, RefundsRequest, EmployeeRefund } from './types';

const tagTypes = ['Refund'];

export const refundApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'refundApi',
  endpoints: (build) => ({
    html: build.query<string, string>({
      query: (id) => ({
        method: 'get',
        url: URL.GET_REFUND_RECEIPT_HTML.replace(':id', id),
      }),
    }),

    employee: build.query<EmployeeRefund, RefundsRequest>({
      query: ({ id, ...query }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.GET_EMPLOYEE_REFUNDS.replace(':id', id.toString()),
          query
        ),
      }),
    }),

    create: build.mutation<Refund, CreateRefund>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: URL.CREATE_REFUND.replace(':id', id.toString()),
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(eventApi.util.invalidateTags(eventTagTypes));
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const { useHtmlQuery, useEmployeeQuery, useCreateMutation } = refundApi;

export type { Refund, EmployeeRefund, RefundsRequest };
