import { useAlert } from 'hooks';
import { acceptPdfAndImageFileTypes } from 'config';
import { handleFormSubmitWithFiles } from 'utils/helpers';
import {
  Row,
  Col,
  PaymentsForm,
  DropZoneContent,
  FileDropzoneField,
} from 'components';

import { FormProps, FormValues } from './types';
import { getInitialValues, getValidationSchema } from './config';

export const PaymentReimbursementForm = ({
  data,
  isEdit,
  onSubmit,
  isDialog,
  children,
  isCompanyOwner,
  ...props
}: FormProps) => {
  const { handleError } = useAlert();

  return (
    <PaymentsForm<FormValues>
      {...props}
      isEdit={isEdit}
      isDialog={isDialog}
      title="reimbursement"
      initialValues={getInitialValues(data)}
      validationSchema={getValidationSchema(isCompanyOwner)}
      onSubmit={handleFormSubmitWithFiles({
        data,
        onSubmit,
        handleError,
        files: [
          {
            formFieldName: 'receipt',
            dataFieldName: 'receiptUrl',
            requestFieldName: 'receiptUrl',
          },
        ],
      })}
    >
      {({ values }) => (
        <>
          {children}
          <Row>
            <Col col={isDialog ? undefined : 4}>
              <FileDropzoneField
                errorMessage
                name="receipt"
                label="Upload receipt"
                accept={acceptPdfAndImageFileTypes}
              >
                <DropZoneContent file={values?.receipt} />
              </FileDropzoneField>
            </Col>
          </Row>
        </>
      )}
    </PaymentsForm>
  );
};
