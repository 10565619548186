import {
  BadgeColor,
  PayoutStatus,
  CustomerStatus,
  ChargebackStatus,
  MonthlyTaxStatus,
  PriceApprovalStatus,
} from './enums';

export const PriceApprovalStatusColor = {
  [PriceApprovalStatus.Rejected]: BadgeColor.Red,
  [PriceApprovalStatus.Pending]: BadgeColor.Yellow,
  [PriceApprovalStatus.Approved]: BadgeColor.Green,
};

export const PayoutStatusColor = {
  [PayoutStatus.Lost]: BadgeColor.Red,
  [PayoutStatus.NotApproved]: BadgeColor.Red,
  [PayoutStatus.Paid]: BadgeColor.TertiaryGreen,
  [PayoutStatus.LostTemporary]: BadgeColor.Yellow,
  [PayoutStatus.Pending]: BadgeColor.TetrieryBlue,
  [PayoutStatus.Approved]: BadgeColor.SecondaryPigmentIndigo,
};

export const ChargebackStatusColor = {
  [ChargebackStatus.Review]: BadgeColor.Pink,
  [ChargebackStatus.ClosedLost]: BadgeColor.Red,
  [ChargebackStatus.ClosedWon]: BadgeColor.Green,
  [ChargebackStatus.ActionRequired]: BadgeColor.Yellow,
};

export const MonthlyTaxStatusStatusColor = {
  [MonthlyTaxStatus.Due]: BadgeColor.Red,
  [MonthlyTaxStatus.Paid]: BadgeColor.Green,
  [MonthlyTaxStatus.PartialPaid]: BadgeColor.Yellow,
};

export const CustomerStatusColor = {
  [CustomerStatus.New]: BadgeColor.Green,
  [CustomerStatus.Existing]: BadgeColor.Blue,
};
