import { useOutletContext } from 'react-router-dom';

import { Info } from 'components/info';
import { List } from 'components/list';
import { Link } from 'components/link';
import { Badge } from 'components/badge';
import { Row, Col } from 'components/grid';
import { Button } from 'components/button';
import { ListCard } from 'components/list-card';
import { BadgeColor, ButtonColors } from 'types';
import { InfoBlock } from 'components/info-block';
import { DatePicker } from 'components/date-picker';
import { ListHeader } from 'components/list-header';
import { formatDate, formatTimeData } from 'utils/date';
import { formatPhone, formatPrice } from 'utils/helpers';
import { useMonthlyPaidMutation } from 'store/api/storage';
import { useAlert, isMobile, isDesktop, handleMutation } from 'hooks';

import { UpdateMonthlyPrice } from '../form';

import * as Styles from './styles';
import { OutletContext } from './types';

const template = '1fr 1fr 64px';
const titles = ['Month', 'Price', ''];
const cellsMobile = [
  { row: '1/2', col: '1/2' },
  { row: '1/2', col: '2/3' },
  { row: '2/3', col: '1/3' },
];

export const StorageDetailsGeneral = () => {
  const mobile = isMobile();
  const desktop = isDesktop();
  const { showSuccessAlert } = useAlert();
  const { data, selectedYear, setSelectedYear } =
    useOutletContext<OutletContext>();

  const [monthlyPaid, mutation] = useMonthlyPaidMutation();
  handleMutation(mutation);

  return (
    <Styles.GeneralContainer>
      <InfoBlock>
        <Row>
          <Col col={3}>
            <Info title="Address" value={data.address} />
          </Col>
          <Col col={3}>
            <Info title="Units" value={data.unit} />
          </Col>
          <Col col={3}>
            <Info title="Gate code" value={data.gateCode} />
          </Col>
          <Col col={3}>
            <Info title="Type" value={data.type} />
          </Col>
        </Row>

        <Row>
          <Col col={4}>
            <Info title="Account under" value={data.accountUnder} />
          </Col>
          <Col col={4}>
            <Info title="Username" value={data.username} />
          </Col>
          <Col col={4}>
            <Info title="User password" value={data.password} />
          </Col>
        </Row>

        <Row>
          <Col col={4}>
            <Info title="Key" value={data.keyCode} />
          </Col>
          <Col col={4}>
            <Info
              title="Hours"
              value={
                data?.workHoursTo && data?.workHoursFrom
                  ? `${formatTimeData(data.workHoursFrom)} - ${formatTimeData(data.workHoursTo)}`
                  : null
              }
            />
          </Col>
          <Col col={4}>
            <Info title="Phone" value={formatPhone(data.phone)} />
          </Col>
        </Row>

        <Row>
          <Col col={4}>
            <Info
              noEllipsis
              title="Website"
              value={
                data.website ? <Link withoutButton url={data.website} /> : null
              }
            />
          </Col>
        </Row>
      </InfoBlock>

      <List
        count={data?.monthlyPrices?.length ?? 0}
        mainWrapperStyles={desktop ? undefined : { padding: 0 }}
        header={<ListHeader titles={titles} template={template} />}
        topHeader={
          <Styles.TopHeaderContainer>
            <Styles.DateContainer>
              <DatePicker
                showYearPicker
                dateFormat="yyyy"
                maxDate={new Date()}
                value={selectedYear}
                onChange={(date) => setSelectedYear(formatDate(date, 'YYYY'))}
              />
            </Styles.DateContainer>

            <UpdateMonthlyPrice data={data} />
          </Styles.TopHeaderContainer>
        }
      >
        {data?.monthlyPrices?.map((value) => (
          <ListCard
            key={value.id}
            headers={titles}
            template={template}
            cellsMobile={cellsMobile}
            tabletTemplate={template}
            values={[
              formatDate(value.date, 'MMMM YYYY'),
              formatPrice(value.price),
              value.isPaid ? (
                <Badge text="Paid" styleType={BadgeColor.Green} />
              ) : (
                <div>
                  <Button
                    text="Pay"
                    fullWidth={mobile}
                    colorType={ButtonColors.LightGreen}
                    onClick={async () => {
                      const response = await monthlyPaid(
                        String(value.id)
                      ).unwrap();
                      if (response.id) {
                        showSuccessAlert(`Monthly price paid successfully`);
                      }
                    }}
                  />
                </div>
              ),
            ]}
          />
        ))}
      </List>
    </Styles.GeneralContainer>
  );
};
