/* eslint-disable perfectionist/sort-objects */

const baseEntityRoutes = {
  New: 'new',
  Edit: ':id',
};

const Auth = {
  Login: '/login',
  SignUp: '/sign-up',
  ResetPassword: '/reset-password',
  ForgotPassword: '/forgot-password',
};

const Profile = {
  Home: '/profile',
  Sales: 'sales',
  Refunds: 'refunds',
  Payments: 'payments',
  Customers: 'customers',
  Documents: 'documents',
  ManageAppointments: 'manage-appointments',
  Reimbursement: {
    New: 'new-reimbursement',
    Details: ':reimbursementId/reimbursement',
    Edit: ':reimbursementId/edit-reimbursement',
  },
  Advances: {
    New: 'new-advances',
    Details: ':advanceId/advances',
    Edit: ':advanceId/advances/edit',
  },
  Bonuses: {
    New: 'new-bonuses',
    Details: ':bonusId/bonuses',
    Edit: ':bonusId/bonuses/edit',
  },
};

const Events = {
  Home: '/events',
  Details: ':id',
  New: 'new',
  Edit: ':id/edit',
};

const Dashboard = {
  Home: '/dashboard',
  Analytics: {
    Home: 'analytics',
    EditTransaction: 'edit-transaction/:id',
  },
  Approval: {
    Details: ':id',
    Home: 'approval',
  },
};

const Accounting = {
  Home: '/accounting',
  CommissionStructure: {
    Home: 'commission-structure',
    Details: ':id',
    Create: 'create',
    Edit: ':id/edit',
  },
  ProfitAndLosses: {
    Home: 'profit-and-losses',
  },
  SalesTax: {
    Taxes: 'taxes',
    Home: 'sales-tax',
    Summary: 'summary',
  },
  Revenue: {
    Home: 'revenue',
    Credit: 'credit',
    Deposit: 'deposit',
    Summary: 'summary',
  },
  Tavla: {
    Home: 'tavla',
    Summary: 'summary',
    Payments: 'payments',
    BackOffice: 'back-office',
    Transactions: 'transactions',
  },
  GiftCards: {
    Home: 'gift-cards',
  },
  Chargebacks: {
    Home: 'chargebacks',
  },
  Transactions: {
    Home: 'transactions',
  },
  Deposit: {
    Home: 'deposit',
  },
  Sales: {
    Home: 'sales',
  },
  Expenses: {
    ...baseEntityRoutes,
    Details: ':id',
    Home: 'expenses',
    Edit: ':id/edit',
    Categories: 'categories',
  },
};

const CustomerCare = {
  Home: '/customer-care',
};

const Customers = {
  Home: '/customers',
  Details: ':id',
  New: 'new',
  Edit: ':id/edit',
};

const Reports = {
  Home: '/reports',
};

const Inventory = {
  Home: '/inventory',
  Event: {
    Home: '/inventory/event',
    Details: {
      Home: ':id',
      InventoryDetails: 'product-inventory/:inventoryId',
    },
  },
  Products: {
    Home: '/inventory/products',
    ...baseEntityRoutes,
    Edit: ':id/edit',
    Details: ':id',
  },
  Suppliers: {
    Home: '/inventory/suppliers',
    ...baseEntityRoutes,
    Edit: ':id/edit',
    Details: ':id',
  },
  Category: {
    Home: '/inventory/category',
    ...baseEntityRoutes,
  },
  Storages: {
    Home: '/inventory/storages',
    ...baseEntityRoutes,
    Edit: ':id/edit',
    Details: {
      Home: ':id',
      General: 'general-info',
      Inventory: 'inventory-info',
      InventoryDetails: ':inventoryId',
    },
  },
  Transfers: {
    Home: '/inventory/transfers',
    ...baseEntityRoutes,
    Edit: ':id/edit',
    Details: ':id',
  },
  Deals: {
    Home: '/inventory/deals',
    ...baseEntityRoutes,
  },
  Attributes: {
    Home: '/inventory/attributes',
    ...baseEntityRoutes,
  },
  DailyCounts: {
    Home: '/inventory/daily-counts',
  },
};

const Users = {
  Home: '/users',
  Details: ':id',
  New: 'new',
  Edit: ':id/edit',
};

const Company = {
  Home: '/company',
  Details: ':id',
  New: 'new',
  Edit: ':id/edit',
};

const Employees = {
  Home: '/employees',
  Details: ':id',
  New: 'new',
  Edit: ':id/edit',
};

const Products = {
  Home: '/products/:id',
  CheckOut: 'checkout',
  Employees: 'employees',
  Dashboard: 'dashboard',
  CashManager: 'cash-manager',
  Questionnare: 'questionnare',
  Inventory: 'inventory/:storageId',
  EmployeeCompetition: 'employee-competition',
  Customers: {
    ...baseEntityRoutes,
    Home: 'customers',
    Details: ':customerId',
    Edit: ':customerId/edit',
  },
  Receipts: {
    HTML: 'html',
    Home: 'receipts',
    Details: ':receiptId',
    Exchange: ':receiptId/exchange',
    RefundHTML: 'refund/:refundId/html',
  },
};

const Shipments = {
  Home: '/shipments',
  Children: {
    StockOrders: {
      Home: 'stock-orders',
      Details: ':id',
      New: {
        Home: 'new',
        Children: {
          OrderInfo: 'order-info',
          Products: 'products',
        },
      },
      Edit: {
        Home: ':id/edit',
        Children: {
          OrderInfo: 'order-info',
          Products: 'products',
        },
      },
    },
    EventOrders: {
      Details: ':id',
      Home: 'event-orders',
    },
  },
};

const Settings = {
  Home: '/settings',
  Children: {
    Themes: 'themes',
    Devices: 'devices',
    Receipts: 'receipts',
    Shipping: 'shipping',
    Carriers: 'carriers',
    Permissions: 'permissions',
    BusinessInfo: 'business-info',
    Integrations: 'intergrations',
    ProductTypes: 'product-types',
    Notifications: 'notifications',
    PaymentMethods: 'payment-methods',
  },
};

const EmployeeCompetition = {
  Home: '/employee-competition',
};

const CompanySettings = {
  Home: '/company-settings',
};

/* eslint-enable perfectionist/sort-objects */

export const AppRoutes = {
  Auth,
  Users,
  Events,
  Company,
  Reports,
  Profile,
  Settings,
  Products,
  Employees,
  Shipments,
  Inventory,
  Dashboard,
  Home: '/',
  Customers,
  Accounting,
  CustomerCare,
  CompanySettings,
  EmployeeCompetition,
  NotFound: '/not-found',
};
