import * as Yup from 'yup';

import { formatDate } from 'utils/date';
import { Payout } from 'store/api/payout';
import { getUploadedFile } from 'utils/helpers';

import { FormValues } from './types';

export const getInitialValues = (data?: Payout): FormValues => ({
  notes: data?.notes || '',
  eventId: data?.eventId || undefined,
  amount: data?.amount?.toString() || '',
  receipt: getUploadedFile(data?.receiptUrl),
  date: data?.date ? formatDate(data?.date) : '',
  employeeId: data?.employeeId?.toString() || '',
});

export const getValidationSchema = (isCompanyOwner?: boolean) => {
  let employeeValidation = Yup.number();

  if (isCompanyOwner) {
    employeeValidation = employeeValidation.required();
  }

  return Yup.object().shape({
    date: Yup.date().required(),
    employeeId: employeeValidation,
    eventId: Yup.number().required(),
    amount: Yup.number().min(1).required(),
    notes: Yup.string().min(5).max(200).required(),
    receipt: Yup.object().required('File is a required field'),
  });
};
