import dayjs from 'dayjs';
import { Outlet, useParams } from 'react-router-dom';

import { useQuery } from 'hooks';
import { AppRoutes } from 'config';
import { useHeader } from 'context';
import { ButtonColors } from 'types';
import { mapQuery } from 'utils/query';
import { Tabs } from 'components/tabs';
import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { Button } from 'components/button';
import { Details } from 'components/details';
import { useDetailsQuery } from 'store/api/storage';

import * as Styles from './styles';
import { StorageDetailsProps } from './types';
import { StorageInventoryPage } from './inventory';

const { General, Inventory } = AppRoutes.Inventory.Storages.Details;

const tabs = [
  {
    to: General,
    label: 'General info',
  },
  {
    to: Inventory,
    label: 'Inventory',
  },
];

export const StorageDetails = ({
  pageName,
  hideTabs,
  hideEdit,
  showOnlyInventory,
  productDetailsPath,
}: StorageDetailsProps) => {
  const { id, inventoryId } = useParams();
  useHeader({ pageName, showBackButton: true });

  const [selectedYear, setSelectedYear] = useQuery(
    'year',
    dayjs().year().toString()
  );

  const { data, ...props } = useDetailsQuery(
    { id: id!, year: selectedYear },
    { skip: !id }
  );

  return (
    <Details {...mapQuery(props)}>
      <Styles.Container
        withoutPadding={!!inventoryId}
        showOnlyInventory={showOnlyInventory}
      >
        {!inventoryId && (
          <>
            <Flex gap={16} alignItems="center">
              <h2>{data?.name}</h2>
              {!hideEdit && (
                <Button
                  to="edit"
                  icon={<Icon.Edit />}
                  testId="edit-button"
                  colorType={ButtonColors.LightBlue}
                />
              )}
            </Flex>

            {!hideTabs && <Tabs items={tabs} />}
          </>
        )}

        {showOnlyInventory ? (
          <StorageInventoryPage productDetailsPath={productDetailsPath} />
        ) : (
          <Outlet context={{ data, selectedYear, setSelectedYear }} />
        )}
      </Styles.Container>
    </Details>
  );
};
