import { Formik } from 'formik';

import { Messages } from 'config';
import { ButtonColors } from 'types';
import { useEditMutation } from 'store/api/storage';
import { useAlert, isMobile, handleMutation } from 'hooks';
import {
  Dialog,
  Button,
  DialogType,
  ButtonTypes,
  CommonStyles,
  NewEntityButtonWithRef,
} from 'components';

import { UpdateMonthlyPriceProps } from './types';
import { MonthlyPriceFields } from './monthly-price-fields';
import { getInitialValues, validationSchema } from './config';

export const UpdateMonthlyPrice = ({ data }: UpdateMonthlyPriceProps) => {
  const mobile = isMobile();
  const { showSuccessAlert } = useAlert();

  const [updateMonthlyPrices, mutation] = useEditMutation();
  handleMutation(mutation);

  if (!data) {
    return null;
  }

  return (
    <Dialog
      removeAutofocus
      title="Monthly Prices"
      closeOnDocumentClick={false}
      width={mobile ? '90vw' : '600px'}
      trigger={<NewEntityButtonWithRef text="Add Prices" />}
    >
      {(close, _, ButtonWrap) => (
        <Formik
          validationSchema={validationSchema}
          initialValues={getInitialValues(data)}
          onSubmit={async ({ monthlyPrices }) => {
            const response = await updateMonthlyPrices({
              ...data,
              monthlyPrices,
            }).unwrap();

            if (response.id) {
              showSuccessAlert(
                `Monthly Prices ${Messages.UPDATED_SUCCESSFULLY}`
              );
              close();
            }
          }}
        >
          {({ dirty, isSubmitting }) => (
            <CommonStyles.Form gap={24}>
              <div>
                <MonthlyPriceFields isDialog />
              </div>

              <ButtonWrap type={DialogType.Plain}>
                <Button
                  text="Cancel"
                  onClick={close}
                  colorType={ButtonColors.LightBlue}
                />
                <Button
                  text="Update"
                  type={ButtonTypes.Submit}
                  disabled={!dirty || isSubmitting}
                />
              </ButtonWrap>
            </CommonStyles.Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
