import { isMobile } from 'hooks';
import { AccountingExpense } from 'types';
import { Dialog, EditButtonWithRef, EditAccountingExpenses } from 'components';

interface EditExpensesProps {
  data: AccountingExpense;
}

export const EditExpense = ({ data }: EditExpensesProps) => {
  const mobile = isMobile();

  return (
    <Dialog
      removeAutofocus
      title="Create Expense"
      trigger={<EditButtonWithRef />}
      width={mobile ? '90vw' : '720px'}
    >
      {(close) => (
        <EditAccountingExpenses
          isDialog
          data={data}
          onClose={close}
          onSuccess={close}
          expenseId={data.id}
        />
      )}
    </Dialog>
  );
};
