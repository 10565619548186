import * as Yup from 'yup';

import { formatDate } from 'utils/date';
import { Chargeback } from 'store/api/accounting';
import { formatPrice, getUploadedFile } from 'utils/helpers';
import {
  ChargebackReason,
  PaymentMethodType,
  ChargebackReasonTitle,
} from 'types';

import { FormValues } from './types';

export const getInfoItems = (data?: Chargeback) => {
  const creditPayment = data?.checkout?.paymentMethods?.find(
    (item) => item.type === PaymentMethodType.Credit
  );

  return [
    {
      value: data?.id,
      title: 'Case Number',
    },
    {
      title: 'Transaction Date',
      value: formatDate(data?.checkout?.createdAt, 'L LT'),
    },
    {
      title: 'Sales Rep',
      value: data?.checkout?.user?.name,
    },
    {
      title: 'Location',
      value: data?.checkout?.event?.name,
    },
    {
      title: 'Dispute Amount',
      value: formatPrice(data?.checkout?.saleTotal),
    },
    {
      title: 'Card',
      value: creditPayment?.last4CC,
    },
    {
      title: 'Due Date',
      value: data?.dueDate && formatDate(data?.dueDate, 'L LT'),
    },
    {
      title: 'Date of Response',
      value: data?.responseDate && formatDate(data?.responseDate, 'L LT'),
    },
    {
      title: 'Customer',
      value: data?.checkout?.customer?.name,
    },
  ];
};

export const reasonOptions = Object.values(ChargebackReason).map((value) => ({
  value,
  label: ChargebackReasonTitle[value],
}));

export const getInitialValues = (data?: Chargeback): FormValues => ({
  reason: data?.reason || '',
  comment: data?.comment || '',
  photo: getUploadedFile(data?.photoUrl),
  dueDate: data?.dueDate ? formatDate(data?.dueDate) : '',
  responseDate: data?.responseDate ? formatDate(data?.responseDate) : '',
});

export const validationSchema = Yup.object().shape({
  reason: Yup.string().required(),
  comment: Yup.string().required(),
  dueDate: Yup.string().required(),
  responseDate: Yup.string().required(),
  photo: Yup.object().required('File is a required field'),
});
