import { useRef, useEffect } from 'react';

import { mapQuery } from 'utils/query';
import { Details } from 'components/details';

import * as Styles from './styles';
import { HTMLViewerProps } from './types';

export const HTMLViewer = function <T extends string>({
  data,
  mapQueryProps,
}: HTMLViewerProps<T>) {
  const frameRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!data || !frameRef.current) {
      return;
    }

    const doc = frameRef.current.contentDocument;
    if (!doc) {
      return;
    }

    doc.open();
    doc.write(data);
    doc.close();
  }, [data, frameRef.current]);

  return (
    <Details {...mapQuery(mapQueryProps)}>
      <Styles.Container ref={frameRef} frameBorder="0" src="about:blank" />
    </Details>
  );
};
