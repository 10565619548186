import styled from 'styled-components';

export const Container = styled.div`
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
`;
