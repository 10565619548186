import { useMemo } from 'react';

import { Messages } from 'config';
import { mapQuery } from 'utils/query';
import { capitalize } from 'utils/string';
import { formatPrice, joinStrings } from 'utils/helpers';
import { CashCardType, ListCardStyle, AccountingExpense } from 'types';
import {
  useExpensesQuery,
  useDeleteExpenseMutation,
} from 'store/api/accounting';
import {
  isMobile,
  handleMutation,
  useSearchQuery,
  useDownloadFile,
} from 'hooks';
import {
  List,
  Flex,
  Loader,
  ListCard,
  ListHeader,
  DeleteDialog,
  DownloadButton,
} from 'components';

import { ExpensesProps } from '../types';
import { CashCards } from '../cash-cards';

import * as Styles from './styles';
import { EditExpense, CreateExpense } from './form';

const template = '1fr 120px 1fr 1fr 1fr 104px';
const titles = ['Category', 'Amount', 'Payment Method', 'Notes', 'Created by'];

const getEventAmount = (
  currentExpense: AccountingExpense,
  eventId: number
): number =>
  Number(
    currentExpense?.events?.find((item) => item?.eventId === eventId)?.amount
  );

export const Expenses = ({ data, event }: ExpensesProps) => {
  const mobile = isMobile();
  const { debounceQuery, ...search } = useSearchQuery();
  const commonSearch = {
    search: debounceQuery,
    eventIds: JSON.stringify([event.id]),
  };

  const { data: accountingExpenses, ...accountingProps } =
    useExpensesQuery(commonSearch);

  const { downloadFile } = useDownloadFile();

  const totalOut = useMemo(
    () =>
      accountingExpenses?.reduce(
        (acc, value) => acc + getEventAmount(value, event?.id),
        0
      ),
    [accountingExpenses]
  );

  const [remove, mutation] = useDeleteExpenseMutation();
  handleMutation({
    ...mutation,
    successMessage: `Expense ${Messages.DELETED_SUCCESSFULLY}`,
  });

  return (
    <Loader isFullScreen={false} isLoading={accountingProps.isLoading}>
      <Flex gap={24} fullHeight flexDirection={mobile ? 'column' : 'row'}>
        <CashCards
          data={data}
          showOnlyCash
          eventId={event.id}
          totalOut={totalOut || 0}
          customTotalCash={totalOut}
          customType={CashCardType.Expenses}
        />

        <Flex gap={24} fullWidth fullHeight flexDirection="column">
          <List
            {...mapQuery(accountingProps)}
            {...search}
            count={accountingExpenses?.length}
            mainWrapperStyles={{ padding: 0 }}
            searchBarRightSlot={<CreateExpense />}
            searchContainerStyle={{ margin: '16px 0' }}
            header={
              <ListHeader padding="8px 0" titles={titles} template={template} />
            }
            searchInputProps={{
              placeholder:
                'Search category, payment method, amount, notes or created by',
            }}
            footer={
              <ListCard
                selected
                padding="16px"
                template={template}
                even={ListCardStyle.Green}
                values={[
                  <p className="semibold">Total</p>,
                  formatPrice(totalOut),
                ]}
              />
            }
          >
            {accountingExpenses?.map((value) => {
              const amount = formatPrice(getEventAmount(value, event?.id));

              return (
                <ListCard
                  key={value.id}
                  padding="16px"
                  headers={titles}
                  template={template}
                  values={[
                    joinStrings(
                      value.categories.map((category) => category.name),
                      ','
                    ),
                    <Styles.Amount isNegative={+value.amount < 0}>
                      {amount}
                    </Styles.Amount>,
                    capitalize(value.paymentMethod),
                    value?.description,
                    joinStrings(
                      value.employees.map((employee) => employee?.user?.name),
                      ','
                    ),
                    <Flex gap={16} justifyContent="flex-end">
                      {value?.receiptUrl && (
                        <DownloadButton
                          url={value.receiptUrl}
                          onDownloadFile={downloadFile}
                        />
                      )}
                      <EditExpense data={value} />
                      <DeleteDialog
                        id={value.id}
                        action={remove}
                        title="Delete Expense"
                        name={`expense with ${amount}`}
                      />
                    </Flex>,
                    ,
                  ]}
                />
              );
            })}
          </List>
        </Flex>
      </Flex>
    </Loader>
  );
};
