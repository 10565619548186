export const paymentTitles = ['Type', 'Amount', 'Tip'];
export const template = 'repeat(3, 1fr) 128px';
export const titles = ['Product', 'Return Price', 'Quantity', 'Damaged'];
export const cellsMobile = [
  { row: '1/2', col: '1/3' },
  { row: '2/3', col: '1/2' },
  { row: '2/3', col: '2/3' },
];

export const commonColProps = {
  col: 3,
  colTablet: 6,
  colMobile: 6,
};
