import { Formik } from 'formik';
import { FormikTouched } from 'formik/dist/types';

import { useHeader } from 'context';
import { ButtonColors } from 'types';
import { isMobile, useNavigate } from 'hooks';
import { StorageFormValues } from 'store/api/storage';
import {
  Col,
  Row,
  Button,
  FormFooter,
  InputField,
  ButtonTypes,
  CommonStyles,
  SectionHeader,
  TimePickerField,
  MonthlyPriceFields,
  AddressAutoCompleteInputField,
} from 'components';

import { FormProps } from './types';
import { validationSchema } from './config';

export const Form = <T extends StorageFormValues | undefined>(
  props: FormProps<T>
) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  const isEdit = 'id' in props.initialValues;

  useHeader({
    showBackButton: true,
    pageName: `${isEdit ? 'Edit' : 'New'} storage`,
  });

  const initialTouched: FormikTouched<StorageFormValues> = {
    workHoursTo: true,
    workHoursFrom: true,
  };

  return (
    <Formik
      {...props}
      initialTouched={initialTouched}
      validationSchema={validationSchema}
    >
      {({ dirty, values, isSubmitting }) => {
        return (
          <CommonStyles.Form flex={1}>
            <CommonStyles.FormInner>
              <Row>
                <Col col={3} colTablet={6}>
                  <InputField
                    name="name"
                    label="Storage Title"
                    placeholder="Add storage name"
                  />
                </Col>

                <Col col={3} colTablet={6}>
                  <AddressAutoCompleteInputField isAddressAutoFill={false} />
                </Col>
              </Row>

              <Row>
                <Col col={3} colTablet={3}>
                  <TimePickerField
                    label="Work Hours"
                    placeholder="From"
                    name="workHoursFrom"
                  />
                </Col>

                <Col col={3} colTablet={3}>
                  <TimePickerField
                    placeholder="To"
                    name="workHoursTo"
                    label="Work hours"
                    minValue={values.workHoursFrom}
                  />
                </Col>

                <Col col={3} colTablet={3}>
                  <InputField
                    type="tel"
                    name="phone"
                    label="Phone"
                    placeholder="Add phone number"
                  />
                </Col>

                <Col col={3} colTablet={3}>
                  <InputField
                    name="website"
                    label="Website"
                    placeholder="Add website URL"
                  />
                </Col>
              </Row>

              <Row>
                <Col col={3} colTablet={4}>
                  <InputField name="unit" label="Unit" placeholder="Add unit" />
                </Col>

                <Col col={3} colTablet={4}>
                  <InputField
                    number
                    name="gateCode"
                    label="Gate Code"
                    placeholder="Add gate code"
                  />
                </Col>

                <Col col={3} colTablet={4}>
                  <InputField
                    name="accountUnder"
                    label="Account Under"
                    placeholder="Add account holder"
                  />
                </Col>
              </Row>

              <Row>
                <Col col={3} colTablet={4}>
                  <InputField
                    number
                    name="keyCode"
                    label="Key Code"
                    placeholder="Add key code"
                  />
                </Col>
                <Col col={3} colTablet={4}>
                  <InputField
                    name="username"
                    label="Username"
                    placeholder="Add username"
                  />
                </Col>

                <Col col={3} colTablet={4}>
                  <InputField
                    name="password"
                    label="Password"
                    placeholder="Add password"
                  />
                </Col>
              </Row>

              <SectionHeader title="Monthly price" />
              <MonthlyPriceFields />
            </CommonStyles.FormInner>
            <FormFooter
              right={
                <CommonStyles.FormButtons>
                  <Button
                    text="Cancel"
                    fullWidth={mobile}
                    onClick={() => navigate(-1)}
                    colorType={ButtonColors.LightBlue}
                  />
                  <Button
                    fullWidth={mobile}
                    type={ButtonTypes.Submit}
                    text={isEdit ? 'Edit' : 'Create'}
                    disabled={!dirty || isSubmitting}
                  />
                </CommonStyles.FormButtons>
              }
            />
          </CommonStyles.Form>
        );
      }}
    </Formik>
  );
};
