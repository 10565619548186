import {
  Children,
  type JSX,
  forwardRef,
  ReactElement,
  cloneElement,
  isValidElement,
} from 'react';

import { Popup } from 'components/popup';
import { useEllipsis, isDesktop as isDesktopHook } from 'hooks';

import * as Styles from './styles';
import { EllipsisTextProps } from './types';

export const EllipsisText = forwardRef<HTMLDivElement, EllipsisTextProps>(
  (
    { as, style, children, className, onPopUpClick, popupChildren },
    ref
  ): JSX.Element => {
    const { use, setRef } = useEllipsis();
    const isDesktop = isDesktopHook();

    return (
      <Popup
        disabled={!use}
        closeOnDocumentClick
        on={['hover', 'focus']}
        maxWidth={isDesktop ? '50vw' : '90vw'}
        position={[
          'bottom left',
          'bottom center',
          'bottom right',
          'top left',
          'top center',
          'top right',
        ]}
        trigger={
          <Styles.EllipsisDiv as={as} style={style} className={className}>
            {Children.map(children, (child) => {
              if (!isValidElement(child)) {
                return <p ref={ref || setRef}>{child}</p>;
              } else {
                return cloneElement(child as ReactElement<any>);
              }
            })}
          </Styles.EllipsisDiv>
        }
      >
        <Styles.Container onClick={onPopUpClick}>
          {Children.map(popupChildren || children, (child) => {
            if (!isValidElement(child)) {
              return child;
            } else {
              const element = child as ReactElement<any>;
              return element.props.children;
            }
          })}
        </Styles.Container>
      </Popup>
    );
  }
);
