import { isDesktop } from 'hooks';
import { List, ListCard, ListHeader, NewEntityButton } from 'components';

import { Card } from './card';
import * as Styles from './styles';
import { TemplateListProps } from './types';

export const TemplateList = function <T>({
  data,
  flex,
  title,
  render,
  onClick,
  subtitle,
  className,
  listProps,
  maxHeight,
  withoutGap,
  titleLevel,
  createPath,
  createTitle,
  cardRightSlot,
  headerPadding,
  headerElement,
  mainWrapperStyles,
  hasMinHeight = true,
  isFooterCreateButton,
  ...props
}: TemplateListProps<T>) {
  const desktop = isDesktop();

  return (
    <Card
      flex={flex}
      title={title}
      subtitle={subtitle}
      className={className}
      maxHeight={maxHeight}
      createPath={createPath}
      titleLevel={titleLevel}
      withoutGap={withoutGap}
      createTitle={createTitle}
      hasMinHeight={hasMinHeight}
      cardRightSlot={cardRightSlot}
      isFooterCreateButton={isFooterCreateButton}
    >
      <List
        hasMinHeight
        count={data?.length ?? 0}
        mainWrapperStyles={mainWrapperStyles || (desktop ? {} : { padding: 0 })}
        {...listProps}
        footer={
          isFooterCreateButton && createTitle && createPath ? (
            <Styles.FooterButtonContainer>
              <NewEntityButton path={createPath} text={createTitle} />
            </Styles.FooterButtonContainer>
          ) : undefined
        }
        header={
          <ListHeader
            template={props.template}
            padding={headerPadding || '8px 16px'}
            titles={
              headerElement && props.headers
                ? [headerElement, ...props.headers]
                : props.headers
            }
          />
        }
      >
        {data?.map((value, index) => (
          <ListCard
            key={index}
            padding="16px"
            {...props}
            values={render(value, index)}
            onClick={onClick ? (e) => onClick(e, value) : undefined}
          />
        ))}
      </List>
    </Card>
  );
};
