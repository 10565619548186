import dayjs from 'dayjs';

import { BadgePadding } from 'types';
import { List } from 'components/list';
import { Flex } from 'components/flex';
import { formatDate } from 'utils/date';
import { capitalize } from 'utils/string';
import { Drawer } from 'components/drawer';
import { Avatar } from 'components/avatar';
import { ListCard } from 'components/list-card';
import { InfoBlock } from 'components/info-block';
import { ListHeader } from 'components/list-header';
import { formatPrice, joinStrings } from 'utils/helpers';
import { RefundStatusBadge } from 'components/refund-status-badge';

import * as Styles from './styles';
import { RefundDetailsProps } from './types';
import {
  titles,
  template,
  cellsMobile,
  paymentTitles,
  commonColProps,
} from './config';

export const RefundDetails = ({
  data,
  opened,
  onClose,
}: RefundDetailsProps) => {
  const commissionsAmount = data?.checkout?.commissions?.reduce(
    (acc, value) => acc + (Number(value?.cash) || 0),
    0
  );

  if (!data) {
    return null;
  }

  return (
    <Drawer opened={opened} topOffset="20%" onClose={onClose}>
      <Styles.StyledDetails
        withoutPhoto
        name="Refund"
        disableSticky
        withPlaceholders
        stickyWithHeader={false}
        sectionWrapper={InfoBlock}
        containerStyles={{ gap: '16px' }}
        sections={[
          {
            rows: [
              [
                {
                  ...commonColProps,
                  title: 'Date',
                  value: formatDate(
                    dayjs(data?.createdAt).tz(
                      data?.checkout?.event?.timezone?.code
                    )
                  ),
                },
                {
                  ...commonColProps,
                  title: 'Event',
                  value: data?.checkout?.event?.name,
                },
                {
                  ...commonColProps,
                  title: 'Sale Total',
                  value: formatPrice(data?.checkout?.saleTotal),
                },
                {
                  ...commonColProps,
                  title: 'Commissions',
                  value: formatPrice(commissionsAmount),
                },
              ],
              [
                {
                  ...commonColProps,
                  title: 'Refund Tip',
                  value: formatPrice(data?.totalRefundTip),
                },
                {
                  ...commonColProps,
                  title: 'Total Refund Amount',
                  value: formatPrice(data?.totalRefundPayments),
                },
                {
                  ...commonColProps,
                  title: 'Status',
                  value: (
                    <RefundStatusBadge
                      status={data?.status}
                      padding={BadgePadding.Default}
                    />
                  ),
                },
                {
                  col: 3,
                  colTablet: 6,
                  title: 'Return to Inventory',
                  value: data?.isReturnToInventory ? 'Yes' : 'No',
                },
                {
                  col: 3,
                  colTablet: 6,
                  title: 'Sailers',
                  value: joinStrings(
                    data?.checkout?.commissions?.map(
                      (commission) => commission?.user?.name
                    ) || [],
                    ', '
                  ),
                },
              ],
            ],
          },
        ]}
      />

      <Flex column gap={24}>
        <Flex column>
          <h3>Return Items</h3>
          <List
            count={data?.refundItems?.length}
            mainWrapperStyles={{ padding: 0 }}
            header={
              <ListHeader padding="8px" titles={titles} template={template} />
            }
          >
            {data?.refundItems?.map((value) => (
              <ListCard
                key={value.id}
                headers={titles}
                template={template}
                cellsMobile={cellsMobile}
                tabletTemplate={template}
                values={[
                  <Flex gap={8} alignItems="center">
                    <Avatar url={value?.checkoutItem?.product?.image} />
                    <h4>{value?.checkoutItem?.product?.name}</h4>
                  </Flex>,
                  formatPrice(value?.price),
                  value?.quantity,
                  value.isDamaged ? 'Yes' : 'No',
                ]}
              />
            ))}
          </List>
        </Flex>

        <Flex column>
          <h3>Payment Methods</h3>
          <List
            mainWrapperStyles={{ padding: 0 }}
            count={data?.refundPayments?.length}
            header={<ListHeader padding="8px" titles={paymentTitles} />}
          >
            {data?.refundPayments?.map((value) => (
              <ListCard
                key={value.id}
                headers={paymentTitles}
                values={[
                  capitalize(value.type),
                  formatPrice(value?.amount),
                  formatPrice(value?.tip),
                ]}
              />
            ))}
          </List>
        </Flex>
      </Flex>
    </Drawer>
  );
};
