import styled from 'styled-components';

export const Container = styled.iframe`
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  background: white;
  height: calc(100% - 8px);
`;
