import { lazy } from 'react';

const List = lazy(() => import('./list'));
const Receipts = lazy(() => import('./receipts'));
const CheckOut = lazy(() => import('./check-out'));
const Employees = lazy(() => import('./employees'));
const Customers = lazy(() => import('./customers'));
const Dashboard = lazy(() => import('./dashboard'));
const Inventory = lazy(() => import('./inventory'));
const CashManager = lazy(() => import('./cash-manager'));
const Questionnare = lazy(() => import('./questionnare'));
const Exchange = lazy(() => import('./receipts/exchange'));
const CustomerEdit = lazy(() => import('./customers/edit'));
const CustomerNew = lazy(() => import('./customers/create'));
const RefundHTML = lazy(() => import('./receipts/refund-html'));
const CustomerDetails = lazy(() => import('./customers/details'));
const ReceiptHTML = lazy(() => import('./receipts/receipt-html'));
const EmployeeCompetition = lazy(() => import('./employee-competition'));
const ReceiptDetails = lazy(() => import('pages/products/receipts/details'));

export {
  List,
  CheckOut,
  Receipts,
  Exchange,
  Employees,
  Dashboard,
  Customers,
  Inventory,
  RefundHTML,
  ReceiptHTML,
  CashManager,
  CustomerNew,
  Questionnare,
  CustomerEdit,
  ReceiptDetails,
  CustomerDetails,
  EmployeeCompetition,
};
