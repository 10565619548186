import { useMemo } from 'react';
import { Formik } from 'formik';

import { useAppSelector } from 'store';
import { Roles, ButtonColors } from 'types';
import { selectAuthUserRole } from 'store/slices/auth';
import { handleFormSubmitWithFiles } from 'utils/helpers';
import { useAlert, isMobile, handleMutation } from 'hooks';
import { Messages, acceptPdfAndImageFileTypes } from 'config';
import {
  UpdateChargebackRequest,
  useUpdateChargebackMutation,
} from 'store/api/accounting';
import {
  Col,
  Row,
  Icon,
  Info,
  Button,
  Drawer,
  InfoBlock,
  InputField,
  FormFooter,
  SelectField,
  ButtonTypes,
  DrawerHeader,
  CommonStyles,
  DropZoneContent,
  DatePickerField,
  FileDropzoneField,
} from 'components';

import * as Styles from './styles';
import { FormProps, DetailsProps } from './types';
import {
  getInfoItems,
  reasonOptions,
  getInitialValues,
  validationSchema,
} from './config';

const buttons = [
  {
    icon: <Icon.Export />,
    text: 'Export Details',
  },
  {
    text: 'Save PDF',
    icon: <Icon.Download />,
  },
  {
    text: 'Print',
    icon: <Icon.FileFilled />,
  },
];

const commonDateProps = {
  minDate: new Date(),
  placeholderText: 'Select Date',
};

const Form = ({
  data,
  update,
  mobile,
  onClose,
  handleError,
  showSuccessAlert,
}: FormProps) => (
  <Formik
    enableReinitialize
    validationSchema={validationSchema}
    initialValues={getInitialValues(data)}
    onSubmit={handleFormSubmitWithFiles({
      data,
      handleError,
      files: [
        {
          formFieldName: 'photo',
          requestFieldName: 'photoUrl',
        },
      ],
      onSubmit: async (values, { resetForm }) => {
        const response = await update({
          ...values,
          id: data!.id,
        } as UpdateChargebackRequest).unwrap();
        if (response.id) {
          showSuccessAlert(`Chargeback ${Messages.UPDATED_SUCCESSFULLY}`);
          resetForm();
          onClose?.();
        }
      },
    })}
  >
    {({ dirty, values, isSubmitting }) => (
      <CommonStyles.Form>
        <Row>
          <Col col={6}>
            <DatePickerField
              {...commonDateProps}
              name="dueDate"
              label="Due Date"
            />
          </Col>

          <Col col={6}>
            <DatePickerField
              {...commonDateProps}
              name="responseDate"
              label="Response Date"
            />
          </Col>
        </Row>

        <Row>
          <Col col={6}>
            <SelectField
              onlyValue
              name="reason"
              label="Reason"
              options={reasonOptions}
              placeholder="Select Reason"
            />
          </Col>

          <Col col={6}>
            <InputField
              name="comment"
              label="Comment"
              placeholder="Enter comment"
            />
          </Col>
        </Row>

        <Row>
          <Col col={4}>
            <FileDropzoneField
              name="photo"
              errorMessage
              label="Upload photo"
              accept={acceptPdfAndImageFileTypes}
            >
              <DropZoneContent file={values?.photo} />
            </FileDropzoneField>
          </Col>
        </Row>

        <FormFooter
          right={
            <CommonStyles.FormButtons>
              <Button
                text="Cancel"
                onClick={onClose}
                fullWidth={mobile}
                colorType={ButtonColors.LightBlue}
              />
              <Button
                text="Save"
                fullWidth={mobile}
                type={ButtonTypes.Submit}
                disabled={!dirty || isSubmitting}
              />
            </CommonStyles.FormButtons>
          }
        />
      </CommonStyles.Form>
    )}
  </Formik>
);

export const ChargebackDetails = ({ data, opened, onClose }: DetailsProps) => {
  const mobile = isMobile();
  const { handleError, showSuccessAlert } = useAlert();
  const authUserRole = useAppSelector(selectAuthUserRole);
  const infoItems = useMemo(() => getInfoItems(data), [data]);

  const [update, mutation] = useUpdateChargebackMutation();
  handleMutation(mutation);

  const isCompanyOwner = authUserRole === Roles.COMPANY_OWNER;

  return (
    <Drawer topOffset="20%" opened={opened} onClose={onClose}>
      <DrawerHeader
        onClose={onClose}
        title={`Invoice ${data?.id}`}
        buttons={buttons.map((button) => (
          <Button
            disabled
            transparent
            padding="0"
            key={button.text}
            icon={button.icon}
            text={button.text}
            colorType={ButtonColors.LightBlue}
          />
        ))}
      />

      <Styles.Container>
        <InfoBlock>
          <Row>
            {infoItems.map((item, index) => (
              <Col col={2} key={index} colTablet={4}>
                <Info {...item} />
              </Col>
            ))}
          </Row>
        </InfoBlock>

        {isCompanyOwner && (
          <Form
            data={data}
            update={update}
            mobile={mobile}
            onClose={onClose}
            handleError={handleError}
            showSuccessAlert={showSuccessAlert}
          />
        )}
      </Styles.Container>
    </Drawer>
  );
};
