import {
  IdObject,
  Nullable,
  PayoutType,
  PayoutStatus,
  SearchRequest,
} from 'types';

import { Event } from '../event';
import { Employee } from '../employees';

export interface Payout extends IdObject {
  date: string;
  event?: Event;
  amount: number;
  notes?: string;
  type: PayoutType;
  eventId?: number;
  createdAt: string;
  employee: Employee;
  employeeId: number;
  updatedAt?: string;
  receiptUrl?: string;
  status: PayoutStatus;
  reasonReject?: string;
}

export interface TransformPayoutData {
  sales: Payout[];
  bonuses: Payout[];
  refunds: Payout[];
  advances: Payout[];
  disputes: Payout[];
  reimbursements: Payout[];
  paymentHistory: Payout[];
  negativeAdjustmentAndEarnigns: Payout[];
}

export interface PaymentHistory
  extends Pick<Payout, 'date' | 'notes' | 'employee' | 'employeeId'>,
    IdObject {
  total: number;
  items: Payout[];
  paymentMethodId?: Nullable<string>;
}

export interface CreatePayoutRequest
  extends Omit<
    Payout,
    | 'id'
    | 'event'
    | 'status'
    | 'employee'
    | 'createdAt'
    | 'employeeId'
    | 'reasonReject'
  > {
  employeeId?: number;
}

export interface PayoutListRequest {
  id?: string;
  types?: string;
  eventId?: string;
  onlyLead?: boolean;
  employeeId?: string;
  status?: PayoutStatus;
}

export interface ChangeStatusRequest extends IdObject {
  status: PayoutStatus;
  reasonReject?: string;
}

export interface EditPayoutRequest extends CreatePayoutRequest, IdObject {
  employeeId: number;
}

export interface CreatePayrollRequest {
  employeeId: number;
  payoutItems: number[];
}

export type PaymentHistoryRequest = IdObject & SearchRequest;

export const MinusPayoutTypes = [
  PayoutType.Refund,
  PayoutType.Advance,
  PayoutType.NegativeEarnings,
  PayoutType.NegativeAdjustment,
];
