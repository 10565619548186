import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Messages } from 'config';
import { Details } from 'components';
import { mapQuery } from 'utils/query';
import { useAlert, useNavigate, handleMutation } from 'hooks';
import {
  useEditMutation,
  useDetailsQuery,
  StorageFormValues,
} from 'store/api/storage';

import { Form } from './form';

export const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const [edit, mutation] = useEditMutation();

  const { data, ...props } = useDetailsQuery({ id: id! }, { skip: !id });

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Storage ${Messages.UPDATED_SUCCESSFULLY}`);
    navigate(-1);
  }, []);

  handleMutation({ ...mutation, onSuccess });

  return (
    <Details {...mapQuery(props)}>
      {data && (
        <Form<StorageFormValues>
          onSubmit={edit}
          initialValues={{
            ...data,
            monthlyPrices:
              data.monthlyPrices.length > 0
                ? data.monthlyPrices.map((item) => ({
                    ...item,
                    date: new Date(item.date),
                  }))
                : [{ date: undefined, price: undefined }],
          }}
        />
      )}
    </Details>
  );
};
