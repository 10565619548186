import { useMemo } from 'react';

import { Badge } from 'components/badge';
import {
  BadgeColor,
  BadgePadding,
  RefundStatus,
  RefundStatusTitle,
} from 'types';

import { RefundStatusBadgeProps } from './types';

export const RefundStatusBadge = ({
  status,
  refunds,
  ...props
}: RefundStatusBadgeProps) => {
  const isFullRefund = useMemo(
    () => refunds?.some((refund) => refund.status === RefundStatus.Full),
    [refunds]
  );

  return (
    <Badge
      styleType={BadgeColor.Red}
      padding={BadgePadding.Small}
      {...props}
      text={
        RefundStatusTitle[
          status || (isFullRefund ? RefundStatus.Full : RefundStatus.Partial)
        ]
      }
    />
  );
};
