import { Fragment } from 'react';
import { FieldArray, FormikValues, useFormikContext } from 'formik';

import { isMobile } from 'hooks';
import { MonthlyPrice } from 'store/api/storage';
import { Row, Col, AddButton, InputField, DatePickerField } from 'components';

import * as Styles from './styles';
import { MonthlyPriceFieldsProps } from './types';

export const MonthlyPriceFields = ({ isDialog }: MonthlyPriceFieldsProps) => {
  const mobile = isMobile();
  const { values } = useFormikContext<FormikValues>();

  const commonColValue = isDialog ? 5.5 : 3;
  const commonColTabletValue = isDialog ? 5.5 : 6;

  return (
    <FieldArray
      name="monthlyPrices"
      render={({ push, remove }) => (
        <>
          {values.monthlyPrices.map((item: MonthlyPrice, index: number) => (
            <Fragment key={index}>
              <Row>
                <Col col={commonColValue} colTablet={commonColTabletValue}>
                  <DatePickerField
                    label="Date"
                    placeholderText="Date"
                    name={`monthlyPrices.${index}.date`}
                  />
                </Col>

                <Col col={commonColValue} colTablet={commonColTabletValue}>
                  <InputField
                    number
                    label="Price"
                    placeholder="Price"
                    name={`monthlyPrices.${index}.price`}
                  />
                </Col>

                {(item.id || index > 0) && (
                  <Col colTablet={1} col={isDialog ? 1 : 2}>
                    <Styles.StyledDeleteButton
                      onClick={() => remove(index)}
                      text={isDialog && !mobile ? undefined : 'Remove price'}
                    />
                  </Col>
                )}
              </Row>
            </Fragment>
          ))}

          <AddButton text="Add price" onClick={() => push({})} />
        </>
      )}
    />
  );
};
