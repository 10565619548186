import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { RefundPdfUrlEvent } from 'context';
import { refundApi, RefundsRequest } from 'store/api/refund';
import { eventApi, ReceiptDetailsRequest } from 'store/api/event';

/**
 * Thunk to update the `pdfUrl` for a refund in the cache.
 *
 * This thunk updates the cached data in two locations:
 * 1. In the `receiptDetails` query from the eventApi, by matching the receiptId (converted to string)
 *    with the provided checkoutId and then updating the refund item with the matching id.
 * 2. In the `employee` query from the refundApi, by updating the refund item with the matching id.
 *
 * @param {RefundPdfUrlEvent} event - Event containing the refund id, new pdfUrl, and checkoutId.
 * @param {Object} thunkAPI - Redux thunk API object containing dispatch and getState.
 */
export const updateRefundPdfUrlInCache = createAsyncThunk<
  void,
  RefundPdfUrlEvent,
  { state: RootState }
>('pdf/update-refund-cache', async (event, { dispatch, getState }) => {
  const { id, url, checkoutId } = event;
  const state = getState();

  const refundEndpointName = 'employee';
  const receiptEndpointName = 'receiptDetails';

  const receiptDetailsParams = Object.values(state.eventApi.queries)
    .filter(
      (queryDetails) =>
        queryDetails?.endpointName === receiptEndpointName &&
        queryDetails.originalArgs
    )
    .map((queryDetails) => queryDetails!.originalArgs as ReceiptDetailsRequest)
    .filter((args) => args.receiptId === String(checkoutId));

  const refundListParams = Object.values(state.refundApi.queries)
    .filter(
      (queryDetails) =>
        queryDetails?.endpointName === refundEndpointName &&
        queryDetails.originalArgs
    )
    .map((queryDetails) => queryDetails!.originalArgs);

  receiptDetailsParams.forEach((params) => {
    dispatch(
      eventApi.util.updateQueryData(
        receiptEndpointName,
        params,
        (draft: any) => {
          const refunds = draft?.refund;
          if (Array.isArray(refunds)) {
            refunds.forEach((refund: any) => {
              if (refund.id === id) {
                refund.pdfUrl = url;
              }
            });
          }
        }
      )
    );
  });

  refundListParams.forEach((params) => {
    dispatch(
      refundApi.util.updateQueryData(
        refundEndpointName,
        params as RefundsRequest,
        (draft) => {
          draft?.refunds?.forEach((refund) => {
            if (refund.id === id) {
              refund.pdfUrl = url;
            }
          });
        }
      )
    );
  });
});
