import styled, { css } from 'styled-components';

import { flexSpaceBetween } from 'styles';
import { captionRegular, messagesRegular } from 'styles/mixins/fonts';

export const Container = styled.div<{
  withoutPadding?: boolean;
  showOnlyInventory?: boolean;
}>`
  gap: 24px;
  display: flex;
  min-height: 0;
  max-height: 100%;
  flex-direction: column;
  ${({ withoutPadding, showOnlyInventory }) => css`
    padding: ${withoutPadding ? 0 : '16px'};
    ${showOnlyInventory &&
    css`
      height: 100%;
    `}
  `}
`;

export const Title = styled.h4`
  ${messagesRegular};
`;

export const Subtitle = styled.p`
  ${captionRegular};
  color: ${({ theme }) => theme.colors.grey.primary.standard};
`;

export const GeneralContainer = styled.div`
  flex: 1;
  gap: 24px;
  display: flex;
  padding: 0 8px;
  overflow-y: auto;
  flex-direction: column;
`;

export const DateContainer = styled.div`
  ${({ theme }) => css`
    width: ${theme.responsive.isDesktop ? '168px' : 'unset'};
  `}
`;

export const TopHeaderContainer = styled.div`
  ${flexSpaceBetween};
  gap: 8px;
  margin-bottom: 16px;
`;
