import dayjs from 'dayjs';
import { useState } from 'react';

import { useShowMore } from 'hooks';
import { BadgePadding } from 'types';
import { formatDate } from 'utils/date';
import { Refund } from 'store/api/refund';
import { formatPrice } from 'utils/helpers';
import { TemplateList } from 'components/transactions-list';
import { RefundStatusBadge } from 'components/refund-status-badge';

import { RefundDetails } from './details';
import { RefundsListProps } from './types';

export const refundsConfig = {
  template: '1fr 2fr repeat(6, 1fr)',
  cellsMobile: [
    { row: '2/3', col: '1/2' },
    { row: '1/2', col: '1/3' },
    { row: '2/3', col: '2/3' },
  ],
  headers: [
    'Date',
    'Event',
    'Sale Total',
    'Commissions',
    'Tip',
    'Refund Amount',
    'Refund Tip',
    'Status',
  ],
};

export const RefundsList = ({ data }: RefundsListProps) => {
  const [selectedRefund, setSelectedRefund] = useState<Refund>();
  const { visibleData, ...showMoreProps } = useShowMore(data);

  return (
    <>
      <TemplateList<Refund>
        {...refundsConfig}
        title="Refunds"
        data={visibleData}
        hasMinHeight={false}
        listProps={showMoreProps}
        onClick={(e, value) => setSelectedRefund(value)}
        maxHeight={visibleData?.length ? 350 : undefined}
        render={(refund) => {
          const timezoneDate = dayjs(refund.createdAt).tz(
            refund.checkout?.event?.timezone?.code
          );

          const commissionsAmount = refund?.checkout?.commissions?.reduce(
            (acc, value) => acc + (Number(value.cash) || 0),
            0
          );

          return [
            formatDate(timezoneDate),
            refund.checkout?.event?.name,
            formatPrice(refund.checkout?.saleTotal),
            formatPrice(commissionsAmount),
            formatPrice(refund.checkout?.tip),
            formatPrice(refund?.totalRefundPayments),
            formatPrice(refund?.totalRefundTip),
            <RefundStatusBadge
              status={refund.status}
              padding={BadgePadding.Default}
            />,
          ];
        }}
      />

      <RefundDetails
        data={selectedRefund!}
        opened={!!selectedRefund}
        onClose={() => setSelectedRefund(undefined)}
      />
    </>
  );
};
