import { useMemo } from 'react';

import { Messages } from 'config';
import { useAppSelector } from 'store';
import { isMobile, handleMutation } from 'hooks';
import { EventTeamMember } from 'store/api/event';
import { selectAuthUserRole } from 'store/slices/auth';
import { Roles, PayoutType, ButtonColors } from 'types';
import { Payout, useCreateMutation } from 'store/api/payout';
import {
  Row,
  Col,
  Button,
  Dialog,
  DialogType,
  SelectField,
  ButtonTypes,
  NewEntityButtonWithRef,
  PaymentReimbursementForm,
} from 'components';

import { CreateReimbursementProps } from './types';

const getEmployeeOptions = (employees?: (EventTeamMember | undefined)[]) =>
  employees
    ?.filter((value) => value?.id)
    ?.map((value) => ({
      label: String(value?.name),
      value: String(value?.employee?.id),
    }));

export const CreateReimbursement = ({ event }: CreateReimbursementProps) => {
  const mobile = isMobile();
  const authUserRole = useAppSelector(selectAuthUserRole);

  const [create, mutation] = useCreateMutation();
  handleMutation({
    ...mutation,
    successMessage: `Reimbursement ${Messages.ADDED_SUCCESSFULLY}`,
  });

  const isCompanyOwner = authUserRole === Roles.COMPANY_OWNER;

  const employeeOptions = useMemo(
    () =>
      isCompanyOwner
        ? getEmployeeOptions([event?.leader, ...(event?.teamMembers || [])])
        : [],
    [isCompanyOwner, event?.leader, event?.teamMembers]
  );

  return (
    <Dialog
      title="Add reimbursement"
      trigger={<NewEntityButtonWithRef text="Add reimbursement" />}
    >
      {(close, _, ButtonWrap) => (
        <PaymentReimbursementForm
          isDialog
          hideBackButton
          isDisableEvent
          isCompanyOwner={isCompanyOwner}
          data={{ eventId: event.id } as Payout}
          onSubmit={async ({ isTeamLead, ...values }) => {
            const response = await create({
              ...values,
              amount: +values.amount,
              type: PayoutType.Reimbursements,
              employeeId: values.employeeId
                ? Number(values.employeeId)
                : undefined,
            }).unwrap();

            if (response.id) {
              close();
            }
          }}
          footerButtons={({ dirty, isSubmitting }) => (
            <ButtonWrap type={DialogType.Plain}>
              <Button
                text="Cancel"
                onClick={close}
                fullWidth={mobile}
                colorType={ButtonColors.LightBlue}
              />
              <Button
                text="Add"
                fullWidth={mobile}
                type={ButtonTypes.Submit}
                disabled={!dirty || isSubmitting}
              />
            </ButtonWrap>
          )}
        >
          {isCompanyOwner ? (
            <Row>
              <Col>
                <SelectField
                  onlyValue
                  label="Employee"
                  name="employeeId"
                  options={employeeOptions}
                />
              </Col>
            </Row>
          ) : undefined}
        </PaymentReimbursementForm>
      )}
    </Dialog>
  );
};
